import * as schemaItemTypes from './schemaItemTypes';

const dependentProperties = {
  BUILDINGS: 'buildings',
  PROJECTS: 'projects',
  UNITS: 'units',
  ROOMS: 'rooms',
  CLIENTS: 'clients',
  PAYMENT_TYPE: 'paymentTypes',
};

export const schema = [
  {
    type: schemaItemTypes.MANY_CALENDAR_PROJECTS,
    label: 'label.projects',
    prop: dependentProperties.PROJECTS,
    isCalendar: true,
  },
  {
    type: schemaItemTypes.MANY_CALENDAR_BUILDINGS,
    label: 'label.buildings',
    prop: dependentProperties.BUILDINGS,
    isCalendar: true,
  },
  {
    type: schemaItemTypes.MANY_CALENDAR_UNITS,
    label: 'label.units',
    prop: dependentProperties.UNITS,
    isCalendar: true,
  },
  // {
  //   type: schemaItemTypes.MONTH_DATE,
  //   prop: 'rentalPeriod',
  // },
];

export { schema as default };
