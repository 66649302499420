<template>
  <div class="calendar__chart-timeline-grid-bookings">
    <div
      v-for="(item, index) in items"
      :key="item.id"
      class="calendar__chart-timeline-grid-bokkings-item"
      :style="{ height: index ? cellHeight + 'px' : cellHeight + 1 + 'px' }"
    >
      <div
        v-for="booking in item.bookings"
        :key="booking.usageId"
        class="booking-item"
        :style="booking.style"
        @click="$emit('open-usage-info', [booking.usageId, item.index])"
      >
        {{ getName(booking.firstName, booking.lastName, booking.style?.width) }}
      </div>
      <div
        v-for="booking in item.archivedBookings"
        :key="booking.usageId"
        class="booking-item --archive"
        :style="booking.style"
        @click="$emit('open-usage-info', [booking.usageId, item.index])"
      >
        {{ getName(booking.firstName, booking.lastName, booking.style?.width) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CalendarChartTimelineGrid',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    cellHeight: {
      type: Number,
      default: 56,
    },
  },

  methods: {
    getName(firstName, lastName, width) {
      const fixedWidth = Number(parseFloat(width).toFixed());
      const lettersWidth = 9;

      const firstNameLength = firstName.length * lettersWidth;
      const lastNameLength = lastName.length * lettersWidth;

      if (firstNameLength + lastNameLength < fixedWidth) {
        return `${firstName} ${lastName}`;
      }

      if (firstNameLength + lettersWidth < fixedWidth) {
        return `${firstName} ${lastName && `${lastName[0]}.`}`;
      }

      if (firstNameLength + lastNameLength > fixedWidth) {
        return `${firstName[0]}. ${lastName && `${lastName[0]}.`}`;
      }

      return `${firstName} ${lastName}`;
    },
  },
};
</script>

<style lang="scss">
.calendar {
  &__chart-timeline-grid-bokkings {
    position: relative;
  }

  &__chart-timeline-grid-bokkings-item {
    position: relative;

    .booking-item {
      position: absolute;
      white-space: nowrap;
      top: 50%;
      transform: translate(0, -50%);
      height: calc(100% - 20px);
      min-height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(106, 137, 239);
      color: #ffffff;
      transition: background-color 0.2s, color 0.2s;
      cursor: pointer;

      &:hover {
        background: rgba(106, 137, 239, 0.3);
        color: #002fff;
      }

      &.--archive {
        background: rgb(142, 142, 142);

        &:hover {
          background: rgba(142, 142, 142, 0.3);
          color: rgb(142, 142, 142);
        }
      }
    }
  }
}
</style>
