/* eslint-disable */
import client from '@/http/client';

const BASE_URL = '/realty';

class CalendarService {
  async getCalendarList(bodyRequest, params, config) {
    return client.post(`${BASE_URL}/calendar/`, bodyRequest, { ...config, params })
  }

  async createBooking(bodyRequest) {
    return client.post(`${BASE_URL}/calendar/bookings/`, bodyRequest)
  }

  async getBookingInfo(bookingId) {
    return client.get(`${BASE_URL}/calendar/bookings/${bookingId}/`)
  }

  async updateBooking(bodyRequest, bookingId) {
    return client.put(`${BASE_URL}/calendar/bookings/${bookingId}/`, bodyRequest)
  }
}

export default new CalendarService();