<template>
  <div class="calendar__chart">
    <calendar-chart-calendar
      ref="calendar"
      :items="visibleDates"
      :months-items="visibleMonths"
      :viewport-height="viewportHeight"
      :viewport-width="viewportWidth"
      :current-date="currentDate"
      :total-width="totalWidth"
      :scroll-left="scrollLeft"
      :offset-x="offsetXTest"
      :first-width="firstWidth"
      :last-width="lastWidth"
    />
    <calendar-chart-timeline
      ref="timeline"
      :items="visibleBookings"
      :date-items="visibleDates"
      :total-height="totalHeight"
      :total-width="totalWidth"
      :offset-y="offsetY"
      :offset-x="offsetX"
      :scroll-top="scrollTop"
      :scroll-left="scrollLeft"
      @open-usage-info="$emit('open-usage-info', $event)"
    />
  </div>
</template>

<script>
import {
  format,
  sub,
  addMonths,
  differenceInDays,
  differenceInCalendarMonths,
  addDays,
  subDays,
  getDate,
  startOfMonth,
  endOfMonth,
  getISODay,
  getDaysInMonth,
  isToday,
  isAfter,
  isBefore,
  areIntervalsOverlapping,
  startOfDay,
  isSameMonth,
} from 'date-fns';
import { translateShortDate, translateShortWeek } from '@/utils/dateFormatting';

import CalendarChartCalendar from './CalendarChartCalendar.vue';
import CalendarChartTimeline from './CalendarChartTimeline.vue';

export default {
  name: 'CalendarChart',

  components: {
    CalendarChartCalendar,
    CalendarChartTimeline,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    cellHeight: {
      type: Number,
      default: 56,
    },
    cellWidth: {
      type: Number,
      default: 32.65,
    },
    viewportHeight: {
      type: Number,
      default: 0,
    },
    viewportWidth: {
      type: Number,
      default: 0,
    },
    totalHeight: {
      type: Number,
      default: 0,
    },
    offsetY: {
      type: Number,
      default: 0,
    },
    scrollTop: {
      type: Number,
      default: 0,
    },
    currentDate: {
      type: Date,
      default: new Date(),
    },
  },

  data() {
    return {
      datesList: [],
      monthsList: [],
      monthsCount: 49,
      scrollLeft: 0,
      nodePadding: 5,
    };
  },

  computed: {
    startIndex() {
      let startNode = Math.floor(this.scrollLeft / this.cellWidth);
      startNode = Math.max(0, startNode);
      return startNode;
    },

    itemCount() {
      return this.datesList.length;
    },

    totalWidth() {
      return this.itemCount * this.cellWidth;
    },

    visibleNodeCount() {
      return Math.ceil((this.viewportWidth - this.firstWidth) / this.cellWidth) + 1;
    },

    visibleBookings() {
      if (this.items.length) {
        const startVisibleDate = subDays(this.visibleDates[0].value, 1);
        const endVisibleDate = this.visibleDates[this.visibleDates.length - 1].value;

        return this.items.map(item => {
          return {
            ...item,
            bookings: item.bookings.map(booking => this.normalizeBooking(booking, startVisibleDate, endVisibleDate)),
            archivedBookings: item.archivedBookings.map(booking =>
              this.normalizeBooking(booking, startVisibleDate, endVisibleDate)
            ),
          };
        });
      }

      return this.items;
    },

    visibleDates() {
      return this.datesList.slice(this.startIndex, this.startIndex + this.visibleNodeCount);
    },

    visibleMonths() {
      const monthList = [];
      if (!this.visibleDates.length) {
        return monthList;
      }
      const firstDate = this.visibleDates[0].value;
      const lastDate = this.visibleDates[this.visibleDates.length - 1].value;
      const monthsCount = differenceInCalendarMonths(lastDate, firstDate) + 1;

      for (let i = 0; i < monthsCount; i += 1) {
        const date = i ? startOfMonth(addMonths(firstDate, i)) : addMonths(firstDate, i);
        const id = `${date.getFullYear()}-${date.getMonth()}`;
        const label = translateShortDate(format(date, 'L-yyyy'));

        let daysToEnd = 0;
        let width = 0;

        if (i !== monthsCount - 1) {
          daysToEnd = differenceInDays(endOfMonth(date), date) + 1;
        } else {
          daysToEnd = differenceInDays(lastDate, date) + 1;
        }

        if (i === 0) {
          width = daysToEnd * this.cellWidth - (this.cellWidth - this.firstWidth);
        } else if (i === monthsCount - 1) {
          width = daysToEnd * this.cellWidth - (this.cellWidth - this.lastWidth);
        } else {
          width = daysToEnd * this.cellWidth;
        }

        const month = { id, label, width, value: date };
        monthList.push(month);
      }

      return monthList;
    },

    offsetX() {
      return this.startIndex * this.cellWidth;
    },

    offsetXTest() {
      return this.startIndex * this.cellWidth;
    },

    firstWidth() {
      return this.cellWidth - (this.scrollLeft - this.startIndex * this.cellWidth);
    },

    lastWidth() {
      return (
        this.viewportWidth -
        this.cellWidth * (this.visibleNodeCount - 1) +
        this.scrollLeft -
        this.startIndex * this.cellWidth +
        1
      );
    },
  },

  watch: {
    currentDate(newDate) {
      if (!isSameMonth(newDate, this.visibleMonths[0].value)) {
        this.setDates();
      }
    },
    visibleMonths(newValue, oldValue) {
      if (newValue[0]?.label !== oldValue[0]?.label) {
        this.$emit('change-month', newValue[0].value);
      }
    },
  },

  mounted() {
    this.setDates();
    this.$refs.calendar.$refs.scroller.addEventListener('scroll', e => this.handleScroll(e.target));
    this.$refs.timeline.$refs.timeline.addEventListener('scroll', e => this.handleScroll(e.target));
  },

  methods: {
    handleScroll(block) {
      this.scrollLeft = block.scrollLeft;
    },

    setDates() {
      this.datesList = [];
      this.monthsList = [];

      const startMonth = sub(startOfMonth(this.currentDate), { years: 2 });
      let datesCount = 0;

      for (let i = 0; i < this.monthsCount; i += 1) {
        const month = addMonths(startMonth, i);

        this.monthsList.push({
          label: translateShortDate(format(month, 'L-yyyy')),
          width: getDaysInMonth(month) * this.cellHeight,
          value: month,
        });

        datesCount += getDaysInMonth(month);
      }

      for (let i = 0; i < datesCount; i += 1) {
        const nextDate = addDays(startMonth, i);

        this.datesList.push({
          id: `${nextDate.getFullYear()}-${nextDate.getMonth()}-${getDate(nextDate)}`,
          day: getDate(nextDate),
          isToday: isToday(nextDate),
          week: translateShortWeek(getISODay(nextDate)),
          value: nextDate,
        });
      }

      this.scrollLeft = differenceInDays(startOfMonth(this.currentDate), startMonth) * this.cellWidth + 5;
    },

    formatDate(date) {
      return format(new Date(date), 'L-yyyy');
    },

    normalizeBooking(booking, startVisibleDate, endVisibleDate) {
      let width;
      const startDate = startOfDay(new Date(booking.rentalPeriod.lower));
      const endDate = startOfDay(new Date(booking.rentalPeriod.upper));

      const intervalsIsOverlapping = areIntervalsOverlapping(
        { start: startVisibleDate, end: addDays(endVisibleDate, 1) },
        { start: startDate, end: endDate }
      );

      const isVisibleStartDate = isAfter(startDate, startVisibleDate); // дата начала аренды после видимой начальной даты
      const isVisibleEndDate = isBefore(endDate, addDays(endVisibleDate, 1)); // дата окончания аренды до видимой последней даты

      if (intervalsIsOverlapping) {
        const difStartDays = differenceInDays(startDate, startVisibleDate);
        const difStartToEndVisibleDays = differenceInDays(endVisibleDate, startDate);
        const difEndToStartVisibleDays = differenceInDays(endDate, startVisibleDate) + 1;

        const margin = 2;
        const cellOffset = this.cellWidth / 2 + margin;

        const left = isVisibleStartDate
          ? `${(difStartDays - 1) * this.cellWidth + cellOffset}px`
          : `${this.cellWidth - this.firstWidth}px`;

        // Если начало и конец в зоне видимости
        if (isVisibleStartDate && isVisibleEndDate) {
          width = differenceInDays(endDate, startDate) * this.cellWidth - margin * 2;
        }

        // Если только начало в зоне видимости
        if (isVisibleStartDate && !isVisibleEndDate) {
          width = (difStartToEndVisibleDays - 1) * this.cellWidth + cellOffset + this.lastWidth - margin;
        }

        // Если только конец в зоне видимости
        if (!isVisibleStartDate && isVisibleEndDate) {
          width = (difEndToStartVisibleDays - 1) * this.cellWidth - Number(parseFloat(left)) - cellOffset;
        }

        // Если начало и конец за пределами календаря
        if (!isVisibleStartDate && !isVisibleEndDate) {
          width = this.viewportWidth;
        }

        const borderRadiusLeft = isVisibleStartDate ? '8px' : '0px';
        const borderRadiusRight = isVisibleEndDate ? '8px' : '0px';

        return {
          ...booking,
          style: {
            left,
            width: width < 0 ? 0 : `${width}px`,
            'border-radius': `${borderRadiusLeft} ${borderRadiusRight} ${borderRadiusRight} ${borderRadiusLeft}`,
          },
        };
      }

      return {
        ...booking,
        style: {
          display: 'none',
        },
      };
    },
  },
};
</script>

<style lang="scss">
.calendar {
  &__chart {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    position: relative;
  }

  &__chart-scrollbar {
    overflow: hidden;
    position: absolute;

    &.--vertical {
      top: 116px;
      bottom: 15px;
      right: 0;
      width: 15px;
      height: calc(100% - 116px - 15px);

      .calendar__chart-scrollbar-inner {
        width: 100%;
        height: 100px;
      }
    }

    &.--horizontal {
      bottom: 0;
      right: 15px;
      width: calc(100% - 15px);
      height: 15px;

      .calendar__chart-scrollbar-inner {
        width: 100px;
        height: 100%;
      }
    }
  }

  &__chart-scrollbar-inner {
    background: rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    position: absolute;
    touch-action: none;
    cursor: grab;
  }
}
</style>
