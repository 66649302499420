<template>
  <div ref="calendar" class="calendar">
    <calendar-modal ref="usageInfo" />

    <calendar-list
      ref="list"
      :items="visibleItems"
      :viewport-height="viewportHeight"
      :cell-height="cellHeight"
      :offset-y="offsetY"
      :scroll-top="scrollTop"
      :total-height="totalHeight"
    />
    <calendar-chart
      ref="chart"
      :items="visibleItems"
      :viewport-height="viewportHeight"
      :viewport-width="viewportWidth"
      :cell-height="cellHeight"
      :cell-width="cellWidth"
      :offset-y="offsetY"
      :current-date="currentDate"
      :scroll-top="scrollTop"
      :total-height="totalHeight"
      @open-usage-info="openUsageInfo"
      @change-month="changeDate"
    />
  </div>
</template>

<script>
import { isAfter, format } from 'date-fns';
// Http
import client from '@/http/client';

// Services
import calendarService from '@/services/calendar';

// Utils
import { debounce } from '@/utils/delay';
import { flushPromises } from '@/utils/scheduler';
import { checkEmptyParams, extractParamsFromMultiSelectObject } from '@/utils/many';

// Components
import CalendarModal from '@/components/Calendar/CalendarModal.vue';
import CalendarList from './CalendarList.vue';
import CalendarChart from './CalendarChart.vue';

export default {
  name: 'Graph',

  components: { CalendarList, CalendarChart, CalendarModal },

  props: {
    currentDate: {
      type: Date,
      required: true,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },

  provide() {
    return {
      updateBooking: this.updateBooking,
      archiveBooking: this.archiveBooking,
    };
  },

  data() {
    return {
      items: [],
      currentItems: [],
      visibleItemsCount: 0,
      totalItemsCount: null,
      cellHeight: 56,
      cellWidth: 32.65,
      initialHeight: 0,
      viewportHeight: 0,
      viewportWidth: 0,

      isLoadingData: false,

      limit: 100,
      offset: 0,

      start: 0,
      translateY: 0,
      translateX: 0,
      nodePadding: 4,

      scrollTop: 0,

      debounceGetList: null,
      needUpdateList: true,
    };
  },

  computed: {
    startIndex() {
      let startNode = Math.floor(this.scrollTop / this.cellHeight) - this.nodePadding;
      startNode = Math.max(0, startNode);
      return startNode;
    },

    itemCount() {
      return this.items.length;
    },

    totalHeight() {
      return this.items.length * this.cellHeight;
    },

    visibleNodeCount() {
      let count = Math.ceil(this.viewportHeight / this.cellHeight) + 2 * this.nodePadding;
      count = Math.min(this.itemCount - this.startIndex, count);
      return count;
    },

    visibleItems() {
      return this.items.slice(this.startIndex, this.startIndex + this.visibleNodeCount);
    },

    offsetY() {
      return this.startIndex * this.cellHeight;
    },
  },

  watch: {
    filters: {
      handler() {
        this.debounceGetList();
      },
      deep: true,
    },
    currentDate() {
      if (this.needUpdateList) {
        this.debounceGetList();
      } else {
        this.needUpdateList = true;
      }
    },
  },

  created() {
    this.debounceGetList = debounce(this.getList, 300);
  },

  mounted() {
    setTimeout(() => {
      this.getTimelineSizes();
    });

    this.getList();

    this.$refs.list.$refs.scroller.addEventListener('scroll', e => this.handleScroll(e.target));
    this.$refs.chart.$refs.timeline.$refs.timeline.addEventListener('scroll', e => this.handleScroll(e.target));

    window.onresize = () => this.getTimelineSizes();
  },

  methods: {
    changeDate(date) {
      this.needUpdateList = false;
      this.$emit('change-month', date);
    },

    async getList(offset = 0) {
      if (this.$options.cancelSource) {
        this.$options.cancelSource.cancel();
        await flushPromises();
      }

      this.isLoading = true;

      this.totalItemsCount = 0;
      this.offset = 0;
      this.items = [];

      try {
        const cancelSource = client.getCancelToken();
        this.$options.cancelSource = cancelSource;
        const config = { cancelToken: cancelSource.token };

        const { count, results } = await this.fetchData(offset, config);

        this.totalItemsCount = count;
        this.offset = results.length;
        this.addItemsToList(results);
      } finally {
        this.isLoading = false;
        this.$options.cancelSource = null;
      }
    },

    async updateList() {
      if (this.isLoadingData) {
        return;
      }

      this.isLoadingData = true;

      try {
        const { results } = await this.fetchData(this.offset);
        this.offset += results.length;
        this.addItemsToList(results);
      } finally {
        this.isLoadingData = false;
      }
    },

    fetchData(offset, config) {
      return calendarService.getCalendarList(
        this.normalizedFilters(),
        {
          limit: this.limit,
          offset,
          rentalPeriodBefore: this.filters.rentalPeriodBefore,
          rentalPeriodAfter: this.filters.rentalPeriodAfter,
        },
        config
      );
    },

    addItemsToList(newItems) {
      let startIndex = this.items.length ? this.items.length : 0;

      newItems.forEach(unit => {
        this.items.push({ ...unit, type: 'unit', index: startIndex });
        startIndex += 1;
        unit.rooms.forEach(room => {
          this.items.push({ ...room, type: 'room', index: startIndex });
          startIndex += 1;
        });
      });
    },

    addNewBooking(booking) {
      for (let i = 0; i < this.items.length; i += 1) {
        if (this.items[i].id === (booking.room?.id || booking.unit.id)) {
          this.items[i].bookings.push(booking);
          break;
        }
      }
    },

    updateBooking(index, bookingData) {
      const unit = this.items[index];

      if (unit.id === (bookingData.room?.id || bookingData.unit.id)) {
        const booking = unit.bookings.filter(item => {
          return item.usageId === bookingData.usageId;
        })[0];

        booking.rentalPeriod = bookingData.rentalPeriod;
      } else {
        let newIndex;
        unit.bookings = unit.bookings.filter(item => item.usageId !== bookingData.usageId);
        for (let i = 0; i < this.items.length; i += 1) {
          if (this.items[i].id === (bookingData.room?.id || bookingData.unit.id)) {
            this.items[i].bookings.push({
              clientId: bookingData.clientId,
              firstName: bookingData.firstName,
              lastName: bookingData.lastName,
              rentalPeriod: bookingData.rentalPeriod,
              usageId: bookingData.usageId,
            });

            newIndex = this.items[i].index;
            break;
          }
        }
        return newIndex;
      }
      return null;
    },

    archiveBooking(index, booking) {
      const unit = this.items[index];
      const { rentalPeriodStartDate, rentalPeriodEndDate } = booking;

      if (isAfter(new Date(rentalPeriodStartDate), new Date())) {
        unit.bookings = unit.bookings.filter(item => item.usageId !== booking.usageId);
        return;
      }

      if (isAfter(new Date(rentalPeriodEndDate), new Date())) {
        const archiveBooking = {
          clientId: booking.clientId,
          firstName: booking.firstName,
          lastName: booking.lastName,
          rentalPeriod: {
            lower: rentalPeriodStartDate,
            upper: format(new Date(), 'yyyy-L-dd'),
          },
          usageId: booking.usageId,
        };

        unit.bookings = unit.bookings.filter(item => item.usageId !== booking.usageId);
        unit.archivedBookings.push(archiveBooking);
      }
    },

    getTimelineSizes() {
      const chartTimelineEl = this.$refs.chart.$refs.timeline.$el;

      this.initialHeight = chartTimelineEl.clientHeight;
      this.initialWidth = chartTimelineEl.clientWidth;

      this.viewportHeight = this.initialHeight;
      this.viewportWidth = this.initialWidth;

      this.visibleItemsCount = Math.round(this.initialHeight / this.cellHeight) + 2;
    },

    handleScroll(block) {
      this.scrollTop = block.scrollTop;

      if (this.totalHeight <= this.scrollTop + this.viewportHeight + this.cellHeight && this.items.length) {
        this.updateList();
      }
    },

    normalizedFilters() {
      return {
        projects: checkEmptyParams(this.filters?.projects)
          ? undefined
          : extractParamsFromMultiSelectObject(this.filters?.projects),
        buildings: checkEmptyParams(this.filters?.buildings)
          ? undefined
          : extractParamsFromMultiSelectObject(this.filters?.buildings),
        units: checkEmptyParams(this.filters?.units)
          ? undefined
          : extractParamsFromMultiSelectObject(this.filters?.units),
      };
    },

    openUsageInfo([usageId, index]) {
      this.$refs.usageInfo.open(usageId, index);
    },
  },

  cancelSource: null,
};
</script>

<style lang="scss">
.calendar {
  height: calc(100vh - 340px);
  display: flex;
  overflow: hidden;
  font-size: 14px;
  background: #ffffff;

  * {
    box-sizing: border-box;
  }

  &__chart {
    flex: 1 1 100%;
  }
}
</style>
