<template>
  <div class="calendar-filters mb-lg-10">
    <form-builder
      ref="formBuilder"
      v-model="valueModel"
      enable-cache
      remove-label-suffix
      :schema="$options.schema"
      :initial-data="value"
    />
    <schema-form-field-month-date v-model="rentalPeriodModel" @input="$emit('input-date', $event)" />
  </div>
</template>

<script>
// Components
import FormBuilder from '@/components/schema/FormBuilder.vue';
import SchemaFormFieldMonthDate from '@/components/schema/FormFieldMonthDate.vue';

// Models
import { schema } from '@/schemas/calendarFilter.schema';

// Utils
import { createModelWrapper } from '@/utils/components';

export default {
  name: 'Filters',

  components: {
    FormBuilder,
    SchemaFormFieldMonthDate,
  },

  props: {
    value: { type: Object, default: () => ({}) },
    rentalPeriod: { type: String, default: '' },
  },

  data() {
    return {
      rentalPeriodModel: this.rentalPeriod,
    };
  },

  computed: {
    valueModel: createModelWrapper('value', 'input'),
  },

  watch: {
    rentalPeriod(value) {
      this.rentalPeriodModel = value;
    },
  },
  schema,
};
</script>

<style lang="scss">
.calendar-filters {
  display: flex;
  align-items: center;
  column-gap: 16px;

  & > span {
    flex-grow: 1;
  }

  .v-form {
    display: flex;
    align-items: center;
    // Заменила стили span на column-gap
    // тк из-за них кнопки прыгали
    column-gap: 16px;

    span {
      flex-grow: 1;
      // margin-left: 8px;
      // margin-right: 8px;

      // &:first-child {
      //   margin-left: 0;
      // }

      // &:last-child {
      //   margin-right: 0;
      // }
    }
  }
}
</style>
