<template>
  <div ref="timeline" class="calendar__chart-timeline">
    <div class="calendar__chart-timeline-inner" :style="{ height: totalHeight + 'px', width: totalWidth + 'px' }">
      <div class="calendar__chart-timeline-grid" :style="{ transform: `translate(${offsetX}px, ${offsetY}px)` }">
        <div class="calendar__chart-timeline-grid-rows">
          <div
            v-for="(item, index) in items"
            :key="item.id"
            class="calendar__chart-timeline-grid-row"
            :class="{ '--empty': !item.bookings.length && !item.archivedBookings.length }"
            :style="{ height: index ? cellHeight + 'px' : cellHeight + 1 + 'px' }"
          >
            <div
              v-for="date in dateItems"
              :key="date.id"
              class="calendar__chart-timeline-grid-row-cell"
              :style="{ width: cellWidth + 'px' }"
            />
          </div>
        </div>
        <calendar-chart-timeline-grid
          :items="items"
          :cell-height="cellHeight"
          @open-usage-info="$emit('open-usage-info', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CalendarChartTimelineGrid from './CalendarChartTimelineGrid.vue';

export default {
  name: 'CalendarChartTimeline',

  components: {
    CalendarChartTimelineGrid,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    dateItems: {
      type: Array,
      default: () => [],
    },
    cellHeight: {
      type: Number,
      default: 56,
    },
    cellWidth: {
      type: Number,
      default: 32.65,
    },
    viewportHeight: {
      type: Number,
      default: 0,
    },
    viewportWidth: {
      type: Number,
      default: 0,
    },
    totalHeight: {
      type: Number,
      default: 0,
    },
    totalWidth: {
      type: Number,
      default: 0,
    },
    offsetY: {
      type: Number,
      default: 0,
    },
    offsetX: {
      type: Number,
      default: 0,
    },
    scrollTop: {
      type: Number,
      default: 0,
    },
    scrollLeft: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      currentDate: new Date(),
      isFirstRender: true,
    };
  },

  watch: {
    scrollTop(value) {
      this.$refs.timeline.scrollTop = value;
    },
    scrollLeft(value) {
      if (!this.isFirstRender) {
        this.$refs.timeline.scrollLeft = value;
      } else {
        setTimeout(() => {
          this.$refs.timeline.scrollLeft = value;
          this.isFirstRender = false;
        });
      }
    },
  },
};
</script>

<style lang="scss">
.calendar {
  &__chart-timeline {
    height: calc(100% - 116px);
    border-right: 1px solid #e6ebff;
    overflow: auto;
  }

  &__chart-timeline-inner {
    overflow: hidden;
  }

  &__chart-timeline-grid {
    overflow: hidden;
    display: inline-block;
  }

  &__chart-timeline-grid-bookings {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &__chart-timeline-grid-row {
    display: flex;
    text-align: center;
    user-select: none;
    pointer-events: all;
    overflow: hidden;
    width: 100%;

    &.--empty {
      background: #f5f5f5;
    }
  }

  &__chart-timeline-grid-row-cell {
    border-right: 1px solid #e6ebff;
    border-bottom: 1px solid #e6ebff;
    flex-grow: 0;
    flex-shrink: 0;
  }
}
</style>
