<template>
  <div v-if="media.isMobile" class="d-flex flex-column align-center justify-center" style="height: 100%">
    <imageDataMissing class="calendar-mobile-image mb-4" />
    {{ $t('calendar.mobile_message') }}
  </div>
  <div v-else class="calendar-page">
    <create-booking-modal ref="createBookingModal" @add-new-booking="addNewBooking" />

    <div class="page-title">
      <span>{{ $t('navigation.calendar') }}</span>
      <v-btn elevation="0" color="#EEEEEE" class="ms-6" @click="openModalCreateBooking">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>

    <filters v-model="filtersData" :rental-period="formatCurrentDate" @input-date="changeDate" />

    <calendar-graph ref="graph" :current-date="currentDate" :filters="filters" @change-month="changeDate" />
  </div>
</template>

<script>
import imageDataMissing from '@/assets/imageMissingData/missing-data-tasks.svg';
import { format, add, sub, startOfMonth, endOfMonth, isDate } from 'date-fns';
// Components
import Filters from '@/components/Calendar/Filters.vue';
import CalendarGraph from '@/components/Calendar/Graph.vue';
import CreateBookingModal from '@/components/Calendar/CreateBookingModal.vue';

// Models
import { createModelData } from '@/schemas/createModelData';
import { schema } from '@/schemas/calendarFilter.schema';

// Utils
import { clone } from '@/utils/clone';

export default {
  name: 'Calendar',

  components: {
    Filters,
    CalendarGraph,
    CreateBookingModal,
    imageDataMissing,
  },

  inject: ['media'],

  data() {
    return {
      currentDate: new Date(),
      filtersData: createModelData(schema),
      filters: {
        projects: undefined,
        buildings: undefined,
        units: undefined,
        rentalPeriodAfter: format(sub(startOfMonth(new Date()), { years: 2 }), 'yyyy-LL-dd'),
        rentalPeriodBefore: format(add(startOfMonth(new Date()), { years: 2 }), 'yyyy-LL-dd'),
      },
    };
  },

  computed: {
    formatCurrentDate() {
      return format(this.currentDate, 'yyyy-L');
    },
  },

  watch: {
    filtersData: {
      handler(newValue) {
        this.updateFilters(newValue);
      },
      deep: true,
    },
  },

  mounted() {
    this.updateFilters(this.filtersData);
  },

  methods: {
    changeDate(date) {
      this.currentDate = isDate(date) ? date : new Date(date);
    },

    updateFilters(filters) {
      if (filters.projects) {
        this.filters.projects = clone(filters.projects);
      }

      if (filters.buildings) {
        this.filters.buildings = clone(filters.buildings);
      }

      if (filters.units) {
        this.filters.units = clone(filters.units);
      }

      if (filters.rentalPeriod) {
        this.currentDate = new Date(filters.rentalPeriod);
        this.filters.rentalPeriodAfter = format(sub(startOfMonth(this.currentDate), { years: 2 }), 'yyyy-LL-dd');
        this.filters.rentalPeriodBefore = format(add(endOfMonth(this.currentDate), { years: 2 }), 'yyyy-LL-dd');
      }
    },

    openModalCreateBooking() {
      this.$refs.createBookingModal.open();
    },

    addNewBooking(data) {
      const booking = {
        clientId: data.clientId,
        firstName: data.firstName,
        lastName: data.lastName,
        rentalPeriod: {
          lower: format(new Date(data.rentalPeriod.lower), 'yyyy-L-dd'),
          upper: format(new Date(data.rentalPeriod.upper), 'yyyy-L-dd'),
        },
        usageId: data.usageId,
        unit: data.unit,
        room: data.room,
      };

      this.$refs.graph.addNewBooking(booking);
    },
  },
};
</script>

<style>
.calendar-page {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
