<template>
  <base-modal v-model="isOpen" content-class="base-dialog" :title="title" @close="close">
    <template v-if="showEdit" #title>
      <div class="d-flex align-center">
        <v-btn icon color="primary" class="mr-2" @click="openDetailed">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        {{ title }}
      </div>
    </template>
    <div v-if="isLoading" class="d-flex justify-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <template v-else>
      <div v-if="showDetailed && booking">
        <view-builder :schema="$options.viewSchema" :data="booking" />

        <div v-if="!isArchived">
          <v-btn elevation="0" color="primary" @click="openEditing">
            {{ $t('button.edit') }}
            <v-icon :size="20" class="ms-1">mdi-pencil</v-icon>
          </v-btn>
          <v-btn elevation="0" color="secondary" class="primary--text ms-3" @click="openArchiving">
            {{ $t('button.archive') }}
            <v-icon :size="20" class="ms-1">mdi-archive-arrow-down</v-icon>
          </v-btn>
        </div>
      </div>
      <div v-if="showEdit && initialData">
        <form-builder :schema="$options.editSchema" :initial-data="initialData" @submit="saveBooking">
          <template #footer="{ valid }">
            <v-btn :disabled="!valid" :loading="submitLoading" type="submit" elevation="0" color="primary" class="mt-2">
              {{ $t('button.edit') }}
            </v-btn>
          </template>
        </form-builder>
      </div>
      <div v-if="showArchiving">
        <v-row class="mt-8" no-gutters>
          <v-col class="pr-1 pr-md-2" :cols="6">
            <v-btn
              block
              large
              elevation="0"
              :loading="submitLoading"
              color="secondary"
              class="primary--text"
              @click="openDetailed"
            >
              {{ $t('modal_button.cancel') }}
            </v-btn>
          </v-col>

          <v-col class="pl-1 pl-md-2" :cols="6">
            <v-btn block large elevation="0" :loading="submitLoading" color="primary" @click="archiveBook">
              {{ $t('modal_button.yes') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { format } from 'date-fns';

// Utils
import { translateDate } from '@/utils/dateFormatting';

import BaseModal from '@/components/BaseModal.vue';

// Services
import calendarService from '@/services/calendar';
import usersService from '@/services/users';
import notificationService from '@/services/notification';

// Schema
import ViewBuilder from '@/components/schema/ViewBuilder.vue';
import FormBuilder from '@/components/schema/FormBuilder.vue';
import { viewSchema, editSchema } from '@/schemas/booking.schema';

// Constants
import { MODAL_DETAILED, MODAL_EDIT, MODAL_ARCHIVING } from '@/constants/calendar';

export default {
  name: 'CalendarModal',
  components: { BaseModal, ViewBuilder, FormBuilder },
  inject: ['updateBooking', 'archiveBooking'],

  data() {
    return {
      isOpen: false,
      isLoading: false,
      submitLoading: false,
      booking: null,
      index: null,
      showWindow: MODAL_DETAILED,
    };
  },

  computed: {
    title() {
      return this.showArchiving ? this.$t('calendar.deletion_confirmation') : this.$t('calendar.info_about_booking');
    },

    showDetailed() {
      return this.showWindow === MODAL_DETAILED;
    },

    showEdit() {
      return this.showWindow === MODAL_EDIT;
    },

    showArchiving() {
      return this.showWindow === MODAL_ARCHIVING;
    },

    isArchived() {
      return this.booking.status === 'archived';
    },

    initialData() {
      return this.booking
        ? {
            usageId: this.booking.usageId,
            client: this.booking.client,
            clientType: 'tenant',
            project: this.booking.project,
            building: this.booking.building,
            unit: this.booking.unit,
            room: this.booking.room,
            rentalPeriod: `${this.booking.rentalPeriodStartDate} - ${this.booking.rentalPeriodEndDate}` || null,
          }
        : null;
    },
  },

  methods: {
    async open(bookingId, index) {
      this.index = index;
      this.getBookingInfo(bookingId);
      this.isOpen = true;
    },

    openDetailed() {
      this.showWindow = MODAL_DETAILED;
    },

    openEditing() {
      this.showWindow = MODAL_EDIT;
    },

    openArchiving() {
      this.showWindow = MODAL_ARCHIVING;
    },

    async getBookingInfo(bookingId) {
      this.isLoading = true;

      try {
        const booking = await calendarService.getBookingInfo(bookingId);
        this.booking = this.normalizeBookingInfo(booking);
      } finally {
        this.isLoading = false;
      }
    },

    saveBooking(data) {
      this.submitLoading = true;

      const bodyRequest = {
        unit: data.room?.id || data.unit.id,
        rentalPeriod: {
          lower: format(new Date(data.rentalPeriod.split(' - ')[0]), 'yyyy-L-d'),
          upper: format(new Date(data.rentalPeriod.split(' - ')[1]), 'yyyy-L-d'),
        },
      };

      calendarService
        .updateBooking(bodyRequest, data.usageId)
        .then(res => {
          const newIndex = this.updateBooking(this.index, res.data);
          if (newIndex) {
            this.index = newIndex;
          }
          this.booking = this.normalizeBookingInfo(res.data);
          this.showWindow = MODAL_DETAILED;
          notificationService.success(this.$t('calendar.booking_is_changed'), 2000);
        })
        .catch(err => {
          if (err?.response?.data?.non_field_errors?.length) {
            this.$options.notificationItem = notificationService.error(err.response.data.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },

    archiveBook() {
      this.submitLoading = true;
      usersService
        .archiveUsages({
          usages: [this.booking.usageId],
          isArchived: true,
        })
        .then(() => {
          notificationService.success(this.$t('calendar.booking_is_archided'), 2000);
          this.archiveBooking(this.index, this.booking);
          this.close();
        })
        .catch(err => {
          if (err?.response?.data?.non_field_errors?.length) {
            this.$options.notificationItem = notificationService.error(err.response.data.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },

    normalizeBookingInfo(booking) {
      return {
        ...booking,
        client: {
          id: booking.clientId,
          firstName: booking.firstName,
          lastName: booking.lastName,
        },
        rentalPeriodStartDate: format(new Date(booking.rentalPeriod.lower), 'yyyy-L-dd'),
        rentalPeriodEndDate: format(new Date(booking.rentalPeriod.upper), 'yyyy-L-dd'),
        rentalPeriod: this.normalizeDate(booking.rentalPeriod),
      };
    },

    normalizeDate(date) {
      const dateStart = translateDate(format(new Date(date.lower), 'd L yyyy'));
      const dateEnd = translateDate(format(new Date(date.upper), 'd L yyyy'));
      return `${dateStart} - ${dateEnd}`;
    },

    close() {
      this.showWindow = MODAL_DETAILED;
      this.booking = null;
      this.isOpen = false;
    },
  },
  notificationItem: null,
  viewSchema,
  editSchema,
};
</script>
<style lang="scss">
.base-dialog {
  &__text {
    font-weight: $--font-weight-normal;
    font-size: 16px;
    line-height: 24px;
    color: $--black-color-0;
    opacity: 0.8;
  }

  .v-card__title {
    word-break: normal;
  }
}
</style>
